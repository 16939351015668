import React from 'react';
import { useSelector } from 'react-redux';

import { EFilterKeys } from 'api/feed/constants';
import { selectGroupSlugById } from 'store/selectors';

import { Link } from '../Link';
import { Topic } from '../Topic';

interface ITopicProps extends React.ComponentProps<typeof Topic> {
  groupId: string;
  linkProps?: Partial<React.ComponentProps<typeof Link>>;
}

export function TopicLink(props: ITopicProps) {
  const { groupId, linkProps = {}, ...rest } = props;

  const slug = useSelector(selectGroupSlugById(groupId));

  return (
    <Link
      state="group.discussion.feed"
      params={{
        [EFilterKeys.TOPICS]: props.active ? undefined : props.topic.id,
        slug,
      }}
      style={{ maxWidth: '100%', display: 'block' }}
      {...linkProps}
    >
      <Topic tagName="span" {...rest} />
    </Link>
  );
}

TopicLink.displayName = 'TopicLink';
